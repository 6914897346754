@import 'assets/styles/modules/mixins';
@import '~@amzn/awsui-design-tokens/polaris';

.columnFormContainer > div {
    align-items: flex-start;
}

.groupField {
    flex-wrap: nowrap !important;

    > div:first-child {
        flex: 1 1 auto;
    }
}

.unsetMaxWidth {
    max-width: unset !important;
}

.inheritFontStyles {
    > * {
        @include apply_style_with_precedence {
            color: inherit;
            font-size: inherit;
            line-height: inherit;
        }
    }
}

.nestedControl {
    $_sub-input__indent: 3.2rem;
    $_sub-input__border__indent: 1rem;
    $_sub-input__border__style: dashed;
    $_sub-input__border__position-horizontal: calc(
        #{$_sub-input__indent * -1} + #{$_sub-input__border__indent}
    );

    margin: 0 0 0 $_sub-input__indent;
    position: relative;

    &::before {
        border-left: 1px $_sub-input__border__style
            $color-border-control-default;
        bottom: 50%;
        content: '';
        height: 50%;
        left: $_sub-input__border__position-horizontal;
        position: absolute;
    }

    &::after {
        bottom: 50%;
        content: '';
        border-bottom: 1px $_sub-input__border__style
            $color-border-control-default;
        left: $_sub-input__border__position-horizontal;
        position: absolute;
        width: calc(
            #{$_sub-input__indent} - #{$_sub-input__border__indent} - 0.8rem
        );
    }

    input:disabled {
        @include apply_style_with_precedence {
            background-color: transparent;
            color: inherit;
        }
    }
}
