@import '~@amzn/awsui-design-tokens/polaris';
@import '~assets/styles/modules/mixins';

.graphic {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    user-select: none;
    width: 100%;

    > * {
        flex-shrink: 0;
    }

    .ee {
        order: 0;
    }

    .ws {
        order: 2;
    }

    &.give {
        .ee {
            order: 2;
        }

        .ws {
            order: 0;
        }
    }

    .arrows {
        opacity: 0.75;
        order: 1;

        > {
            * {
                opacity: 0.25;
            }

            *:not(:last-child) {
                margin-right: -5px;
            }

            *:nth-last-child(3) {
                opacity: 0.5;
            }

            *:nth-last-child(2) {
                opacity: 0.75;
            }

            *:last-child {
                opacity: 1;
            }
        }
    }
}

.logo {
    background-color: rgba(255, 255, 255, 0.95);
    border: 1px solid $color-border-divider-default;
    border-radius: 1rem;
    box-sizing: border-box;
    height: 64px;
    min-width: 64px;
    padding: 0.5rem;
    width: auto;

    &.ws {
        background-color: #232f3e;
    }

    &.morePadding {
        padding: 1.2rem;
    }
}
