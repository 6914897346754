@import '~@amzn/awsui-design-tokens/polaris';

.markdown {
    table {
        border: 1px solid $color-border-divider-default;
        border-collapse: collapse;
        margin: 1rem 0;

        thead {
            display: none;
        }

        tbody {
            tr {
                &:last-child {
                    border-top: 1px solid $color-border-divider-default;
                }
            }

            td {
                &:first-child {
                    &::after {
                        content: ':';
                    }
                }

                padding: 0.4rem 0.8rem;
            }
        }
    }
}
