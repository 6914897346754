.status {
    // y cant u be normal polaris

    > * {
        font-size: inherit !important;
        line-height: inherit !important;
    }

    span[class*="_icon_"] {
        height: unset !important;
   
        padding-bottom: 0 !important;
        padding-top: 0 !important;

        > span {
            margin-bottom: 0 !important;
            margin-top: 0 !important;
        }
    }
}