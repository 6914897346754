@import '~@amzn/awsui-design-tokens/polaris';
@import 'assets/styles/modules/mixins';

.stackCards {

     div[class*="_has-header_"] {

        @include apply_style_with_precedence {
            margin-top: 0;

            div[class*="_empty_"], div[class*="_loading_"] {
                margin-top: 2rem;
            }
        }
     }

     div[class*="_header_"] {

        @include apply_style_with_precedence {
            border-bottom: 1px solid $color-border-divider-default;
        }
     }

     ol {

        > li {

            > div[class*="_card-inner_"] {

                @include apply_style_with_precedence {
                    margin-bottom: 0;
                }
            }

            &:not(:last-child):not([class*="_card-selected_"]) {

                > div {
                    @include apply_style_with_precedence {
                        border-bottom: 1px solid $color-border-divider-default;
                    }
                }
            }
        }
     }
}

.filter {
    align-items: center;
    display: flex;
    flex-direction: row;

    > * {
        flex: 1 1 auto;
    }

    .countText {
        flex: 0 1 auto;
        margin: 0 0 0 1rem;
    }
}
