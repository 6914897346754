@import 'assets/styles/modules/mixins';

.popoverDescription {
    > * {
        @include apply_style_with_precedence {
            font-size: inherit;
            line-height: inherit;
        }
    }
}
