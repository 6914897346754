.displayItem {
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (min-width: 365px) {
        flex-wrap: unset;
    }

    .label {
        margin-right: 0.5rem;
    }

    .nowrap {
        white-space: nowrap;
    }
}
