.unsetMaxWidth {
    max-width: unset !important;
}

.fieldGroup {
    $p: &;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    #{$p} {
        flex-wrap: nowrap;

        > {
            *:not(:last-child) {
                margin-right: 8px;
            }
        }
    }

    > {

        *:not(:last-child) {
            margin: 0 0 1rem;
        }

        *:not(.noStretch) {
            flex-basis: 100%;
            flex-grow: 1;
            flex-shrink: 1;
        }
    }

    @media(min-width: 543px) {

        > {
            *:not(:last-child) {
                margin: 0 2rem 0 0;
            }
    
            *:not(.noStretch) {
                flex-basis: auto;
            }
        }
    }
}
