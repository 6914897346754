$_desktop__breakpoint: 913px;
$_sticky__top__offset: 3rem;
$_sticky__top__mobile: 48px;
$_sticky__top__desktop: 56px;

.wizard {
    nav > ul {
        position: sticky;
        top: calc(#{$_sticky__top__offset} + #{$_sticky__top__mobile});

        @media (min-width: $_desktop__breakpoint) {
            top: calc(#{$_sticky__top__offset} + #{$_sticky__top__desktop});
        }
    }
}
