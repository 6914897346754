@import '~@amzn/awsui-design-tokens/polaris';
@import 'assets/styles/modules/_layout.scss';

$_landing-page__side-bar__offset-top: 64px;

.header {
    background-color: $color-background-home-header;
}

.headerText {
    color: $color-text-home-header-default;

    > * {
        max-width: 700px;
    }
}

.secondaryContent {
    margin-top: $_landing-page__side-bar__offset-top * -1;
}

.mainContent {
    // Offset 20px vertical padding and apply 32px visual margin
    margin-bottom: $_landing-page__side-bar__offset-top - 20px + 32px;
}

.imageSection {
    align-items: center;
    display: flex;
    flex-direction: row;

    img {
        display: block;
        margin: 0 2rem 0 0;
        width: 72px;
    }

    h3 {
        line-height: 1.4em !important;
    }
}
