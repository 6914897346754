.descriptionBadges {
    $_badge__spacing: .5rem;

    display: flex;
    flex-wrap: wrap;
    margin: 0 0 -4px 0; // Offset container padding
    
    > * {
        margin: ($_badge__spacing / 2) $_badge__spacing $_badge__spacing 0;
    }
}
