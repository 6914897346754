@import './layout';

.collectionSelectFilter {
    align-items: flex-start;
    display: flex;
    order: 0;
    flex-grow: 10;

    .filter {
        order: 0;
        flex-grow: 6;
        width: auto;
        max-width: 728px;
        margin-right: 1rem;

        > * {
            max-width: unset !important;

            > *:empty {
                padding: 0 0 !important;
            }
        }
    }

    .select {
        max-width: 130px;
        flex-grow: 2;
        width: auto;

        &:not(:last-child) {
            margin-right: 1rem;
        }
    }

    .countText {
        $_content_height: 32px;
        $_line-height: 22px;

        margin-top: ($_content_height - $_line-height) / 2;
        white-space: nowrap;
    }

    @media (max-width: $breakpoint-small) {
        flex-wrap: wrap;
        margin-right: 0;

        .filter {
            width: 100%;
            max-width: none;
            margin-bottom: 1rem;
            margin-right: 0;
        }

        .select {
            flex: 1 1 auto;
            max-width: unset;
        }
    }
}
