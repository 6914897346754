.control {
    align-items: center;
    display: flex;
    flex-direction: row;

    > * {
        flex: 1 1 auto;
    }

    .postfix {
        flex-grow: 0;
        margin: 0 0 0 1rem;
        white-space: nowrap;
    }
}
